import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~3],
		"/admin": [6],
		"/admin/check-klarna-captures": [~7],
		"/admin/dashboards/customers": [~8],
		"/admin/dashboards/finance": [~9],
		"/admin/dashboards/operations": [~10],
		"/admin/dashboards/store": [~11],
		"/admin/klarna-order-info": [~12],
		"/admin/orders": [13],
		"/admin/sales": [~14],
		"/admin/start": [15],
		"/aktuellt-i-butiken": [16],
		"/betalning/bekraftelse-klarna": [~17],
		"/billiga_mattor_till_kampanjpris": [~18],
		"/cookie-policy": [19],
		"/integritetspolicy": [20],
		"/kassa": [21],
		"/kontakta-oss-i-boras": [22],
		"/kopa-matta-online": [23],
		"/mattbloggen": [24,[],[2]],
		"/mattbloggen/[post]": [25,[],[2]],
		"/mattor-inspiration": [~26],
		"/mattor-rea": [~27],
		"/mattor_nyheter": [~28],
		"/om-nylanders-mattor": [29],
		"/populara_mattor": [~30],
		"/scripts/send-review-requests/email": [~31],
		"/sok-mattor": [~32],
		"/tack": [~33],
		"/tisca-ullmattor": [34],
		"/villkor": [35],
		"/[category]": [4],
		"/[category]/[product]": [5]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';